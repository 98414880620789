import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

function AboutPage() {
  const Table = styled.table`
    ${tw`table-fixed border-separate`}
  `;
  const Td = styled.td`
    ${tw`border-b p-4`}
  `;
  const TdWidthFixed = styled.td`
    ${tw`border-b p-4`}
    ${tw`w-4/12`}
  `;

  return (
    <Layout>
      <SEO
        keywords={[`会社概要`]}
        title="About"
        description="株式会社CatDrivenの会社概要。"
      />
      <section className="md:w-2/3 mx-auto">
        <h2 className="inline-block py-3 m-4 text-2xl font-bold">会社概要</h2>
        <Table>
          <tbody>
            <tr>
              <TdWidthFixed>会社名</TdWidthFixed>
              <Td>株式会社CatDriven</Td>
            </tr>
            <tr>
              <Td>所在地</Td>
              <Td>
                〒810-0001 <br />
                福岡県福岡市中央区天神二丁目3番10号
                <br />
                天神パインクレスト719号
              </Td>
            </tr>
            <tr>
              <Td>設立日</Td>
              <Td>2020年12月15日</Td>
            </tr>
            <tr>
              <Td>資本金</Td>
              <Td>500,000円</Td>
            </tr>
            <tr>
              <Td>代表取締役</Td>
              <Td>松本 拓也</Td>
            </tr>
            <tr>
              <Td>事業内容</Td>
              <Td>
                システムの受託開発、運用及びコンサルティング
                <br />
                インターネットサービス・スマートフォンアプリケーションの企画、開発、運営
              </Td>
            </tr>
          </tbody>
        </Table>
      </section>
    </Layout>
  );
}

export default AboutPage;
